<template>
    <div class='d-flex align-items-center h-100'>
         <div class='stap-inner' v-if="options && options.length > 0">
              <h1><span class='stap-number'>6.</span> Materiaal</h1>
              <h5 class='stap-beschrijving'>Wil {{aanmeldingNaam}} materiaal huren?</h5>
              <div id="check-onze-site" class='d-block d-md-inline-flex align-items-center'>
                <div class="bg-image-full"
                :style="{'background-image': 'url(' + require('@/assets/overview.jpeg') + ')'}"
                id='check-site-image'
            ></div>
                <div class='ms-3'>
                  <h4 class="d-none d-md-block mb-0"><strong>Actuele informatie</strong></h4>
                    <h6>Op onze website vind je een uitgebreide informatiepagina over materialen en prijzen.</h6>
                    <a href="https://skipistenieuwegein.nl/prijzen-en-voorwaarden#:~:text=2%20april%202023.-,Huur%20materiaal*,-Tijdsduur" target='_blank' class="btn btn-primary">Bekijk informatiepagina</a>
                </div>
              </div>
              <div class="row mt-5">
                  <div id='beschikbare-materialen' class="col-md-12 d-md-grid">
                        <div
                            v-for="o in options" :key="o.id"
                            class='card-option m-0'
                            @click='setMateriaal($event, o)'
                            :class="isSelected(o.id)"
                        >
                            {{o.titel}}
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'StapMateriaal',
  methods: {
    setMateriaal: function (e, materiaal) {
      this.loading = true
      this.$store.dispatch('aanmelding/setMateriaal', materiaal)
      this.$router.push({ name: 'StapSkikaart' })
      this.loading = false
    },
    isSelected: function (id) {
      return {
        selected: this.$store.state.aanmelding.materiaal && this.$store.state.aanmelding.materiaal.id === id
      }
    }
  },
  computed: {
    aanmeldingNaam () {
      return this.$store.state.aanmelding.client.firstname
    },
    fullfill () {
      return !!this.$store.state.aanmelding.activiteit
    },
    options () {
      return this.$store.state.options.materiaal
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    if (!!this.$store.state.aanmelding.cursus && this.$store.state.aanmelding.cursus.isPriveles) {
      this.$router.push({ name: 'StapOpmerkingen' })
    }
    this.$store.dispatch('options/getMateriaal', this.$store.state.aanmelding.id)
  }
}
</script>

<style scoped>
  #check-onze-site{
    background: var(--background-light);
    border-radius: 20px;
    padding: 12px;
  }
  #check-onze-site h6{
    color: rgba(0, 0, 0, 0.7);
  }
  #check-site-image{
    border-radius: 15px;
    width: 240px;
    height: 160px;
  }

  #beschikbare-materialen{
        margin-bottom: 45px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.9rem;
    }

  @media screen and (max-width: 768px) {
    #beschikbare-materialen{
        grid-template-columns: 1fr;
        grid-gap: 0.7rem;
    }
    #check-site-image{
      border-radius: 15px;
      width: 100%;
      height: 120px;
      display: none;
    }
  }
</style>
